
import { CallCheckListModel } from "@/services/types";
import Vue from "vue";
import Component from "vue-class-component";
import CollapseArea from "./CollapseArea.vue";
import { StoreActionTypes, StoreMutationTypes } from "@/store/types";
import {getStore} from "@/store/getStore";

interface CallCheckListRenderItem extends CallCheckListModel {
  state: boolean;
}

@Component({
  components: {
    CollapseArea,
  },
  watch: {
    estimatedChecklistId: 'handleEstimatedChecklistIdChange',
    selectedCallCheckListId: 'handleSelectedCallCheckListId'
  }
})
export default class CallCheckList extends Vue {
  private componentClass = "CallCheckList";

  private renderList: CallCheckListRenderItem[] = [];

  get estimatedChecklistId() {
    return getStore().getters.getSelectedCallEstimatedCheckList?.checklistId
  }
  get selectedCallCheckListId() {
    return getStore().getters.getSelectedCallCheckListId
  }

  handleEstimatedChecklistIdChange(val: number) {
    if (this.estimatedChecklistId) {
      this.setSelectedCallCheckListId(this.estimatedChecklistId)
    } else if (this.renderList[0]) {
      this.setSelectedCallCheckListId(this.renderList[0].id)
    }
  }

  handleSelectedCallCheckListId(val: number) {
    this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      val
    );
  }

  setSelectedCallCheckListId(id: number) {
    getStore().commit(StoreMutationTypes.SET_SELECTED_CALL_CHECK_LIST_ID, id)
  }

  async mounted() {
    const callChecklists = this.$store.getters
      .getSelectedCallChecklists as CallCheckListModel[];

    this.renderList = callChecklists.map((item) => {
      return {
        ...item,
        state: false,
      };
    });

    if (this.estimatedChecklistId) {
      this.setSelectedCallCheckListId(this.estimatedChecklistId)
    } else {
      this.setSelectedCallCheckListId(this.renderList[0].id)
    }
    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      this.selectedCallCheckListId
    );
  }

  async onClick(val: number) {
    this.setSelectedCallCheckListId(val)

    this.$store.commit(
      StoreMutationTypes.SET_SELECTED_CALL_CURRENT_ESTIMATION_CLEAR,
      []
    );
    this.$emit("load");

    await this.$store.dispatch(
      StoreActionTypes.CHECKLISTS_FULL_LIST_GET,
      this.selectedCallCheckListId
    );
    await this.$store.dispatch(
      StoreActionTypes.SET_SELECTED_CALL_CATEGORIES,
      this.selectedCallCheckListId
    );
  }
}
