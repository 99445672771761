
import Vue from "vue";
import Component from "vue-class-component";
import { i18n } from "@/i18n/index";
import CallReviewers from "@/components/callDetails/reviewers/CallReviewers.vue";
import * as utils from "@/services/utils";
import CopyCallLinkButton from "@/components/callDetails/CopyCallLinkButton.vue";
import { getStore } from "@/store/getStore";

@Component({
  components: {
    CopyCallLinkButton,
    CallReviewers,
  },
})
export default class CallDetailsHeader extends Vue {
  private componentClass = "CallDetailsHeader";

  get userCanRate(): boolean {
    return this.$store.getters.getSelectedCallAllCriterionsChecked;
  }

  get callNotRated(): boolean {
    return !this.$store.getters.getSelectedCallRated;
  }

  get getTitle() {
    if (this.$store.getters.getSelectedCallDirection) {
      return this.$store.getters.getSelectedCallDirection == "incoming"
        ? i18n.t("calls.details.title.incoming")
        : i18n.t("calls.details.title.outgoing");
    } else {
      return "";
    }
  }

  get hasEstimatedCheckList(): boolean {
    return (
      this.$store.getters.getSelectedCallEstimatedCheckList &&
      this.$store.getters.getSelectedCallEstimatedCheckList.id
    );
  }

  get getRatingDate(): string {
    let date = "";
    if (this.hasEstimatedCheckList) {
      date = utils.renderDateFromString(
        this.$store.getters.getSelectedCallEstimatedCheckList.createdAt
      );
    }

    return date;
  }

  get callId():number|undefined {
    return getStore().getters.getSelectedCall.id
  }
}
